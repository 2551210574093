import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppConfig, PlatformApi } from '../../configs';
import {
  ActionAuth,
  AxiosAuth,
  StorageAuth,
  useAppDispatch,
  useAppSelector,
  VRIAppStateType
} from '../../core';
import { OperatorStatusType } from '../../models/api/Operator';
import { logger } from '../../utils';

export function useChangeStatus() {
  const dispatch = useAppDispatch();
  const { shouldConnectWS, twilioDeviceStatus, websocketStatus, userId } =
    useAppSelector((state:  VRIAppStateType) => ({
      shouldConnectWS: state.common.shouldConnectWS,
      twilioDeviceStatus: state.common.twilioDeviceStatus,
      websocketStatus: state.common.websocketStatus,
      userId: state.auth.profile?.id,
    }));
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const pendingStatusRef = useRef<OperatorStatusType>();
  const pendingRequestIdRef = useRef<number | null>(null);
  const retryCountRef = useRef(0);
  const [havePendingRequest, setHavePendingRequest] = useState(false);

  const clearPendingDelayedRequest = useCallback(() => {
    if (!pendingRequestIdRef.current) {
      return;
    }
    clearTimeout(pendingRequestIdRef.current);
    pendingRequestIdRef.current = null;
    logger({
      message: 'Cleared pending status update request',
    });
  }, []);

  const cancelPendingRequest = useCallback(() => {
    if (!cancelTokenSourceRef.current) {
      return;
    }
    cancelTokenSourceRef.current.cancel();
    logger({
      message: 'Canceled pending status update request',
    });
  }, []);

  const changeStatus = useCallback(
    (status: OperatorStatusType) => {
      if (AppConfig.tabQueue && !shouldConnectWS) {
        return;
      }
      console.log("xxx changeStatus was called with status: ", status);

      clearPendingDelayedRequest();
      cancelPendingRequest();
      cancelTokenSourceRef.current = axios.CancelToken.source();
      logger({ message: 'Trying to update status to: ', data: status });

      const url = PlatformApi.Operator.ChangeCurrentStatus(userId, status);
      AxiosAuth.put(url, '', {
        cancelToken: cancelTokenSourceRef.current.token,
      })
        .then((response) => {
          if (response.data?.isSuccess) {
            StorageAuth.activeStatus = status;
            // console.log("statuscheck from useChangeStatus, dispatch SetUserStatus to -> ", status)
            dispatch(ActionAuth.SetUserStatus(status));
            logger({ message: 'Status updated to: ', data: status });
            retryCountRef.current = 0;
          } else {
            toast.error(response.data?.message);
            logger({
              message: `Failed to update Status to ${status} : `,
              data: response.data?.message,
            });
            setHavePendingRequest(true);
            pendingStatusRef.current = status;
          }
        })
        .catch((error) => {
          logger({
            message: `Error on updating status to ${status} : `,
            data: error,
          });
          if (!axios.isCancel(error)) {
            setHavePendingRequest(true);
            pendingStatusRef.current = status;
          }
        })
        .finally(() => {
          cancelTokenSourceRef.current = null;
        });
    },
    [
      dispatch,
      userId,
      shouldConnectWS,
      clearPendingDelayedRequest,
      cancelPendingRequest,
    ]
  );

  useEffect(() => {
    if (!havePendingRequest) {
      return;
    }
    if (
      !navigator.onLine ||
      twilioDeviceStatus === 'offline' ||
      !websocketStatus
    ) {
      setHavePendingRequest(false);
      clearPendingDelayedRequest();
      retryCountRef.current = 0;
    } else {
      clearPendingDelayedRequest();
      const exponentialSeconds = 2 ** retryCountRef.current * 1000;
      pendingRequestIdRef.current = window.setTimeout(() => {
        // console.log("statuschecklocal - useChangeStatus A, using the changeStatus function to set user status to:", pendingStatusRef.current!);
        changeStatus(pendingStatusRef.current!);
        setHavePendingRequest(false);
      }, exponentialSeconds);
      retryCountRef.current = retryCountRef.current + 1;
      logger({
        message: `Added pending (${exponentialSeconds} ms) retry update status to: `,
        data: pendingStatusRef.current,
      });
    }
  }, [
    havePendingRequest,
    twilioDeviceStatus,
    websocketStatus,
    changeStatus,
    clearPendingDelayedRequest,
  ]);

  return { changeStatus };
}
