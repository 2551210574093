import React, { useState } from "react";
import { shallowEqual } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { FileBucketURL } from "../../configs/appRoute";
import { useAppSelector, VRIAppStateType} from "../../core";
import { AdminPageTemplate, ColumnToolTip, ModalCallCenterMessageEditModal, } from "../../features";
import { ModalMessageEditModal } from "../../features/modals/messageEditModal";
import { MessageViewModel, } from "../../models";
import { useFunctionalityCallCenterMessage } from "../../pages/hooks";
import {
  PaginationSettings,
} from "../../configs";

export function PageMessage() {
  const [showIvrMessageModal, setShowIvrMessageModal] = React.useState(false);
  const [showCallCenterMessageModal, setShowCallCenterMessageModal] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState<MessageViewModel>(Object);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );

  const currentCallCenterInfo = useAppSelector(
    (state: VRIAppStateType) => ({
      callCenterId:
        state.auth.menuSettings?.callCenterSettings.callCenterId || "",
    }),
    shallowEqual
  );

  const { CallCenterMessageList, onEditMessage } =
    useFunctionalityCallCenterMessage({
      callCenterId: currentCallCenterInfo.callCenterId,
      setLoaderProgress,
    });

  const getCategoryName = (category: string) => {
    const categoryWords = category?.split("_") ?? [];
    const categoryName = categoryWords[0]?.concat(
      " " +
      categoryWords[1]
        ?.charAt(0)
        ?.concat(categoryWords[1]?.slice(1)?.toLowerCase())
    );
    return categoryName;
  };


  const onSaveHandler = (value: FormData, category?: string) => {
    console.log("called onSaveHandler from MessagePage");
    const categoryName = getCategoryName(category ?? "");
    onEditMessage(currentCallCenterInfo.callCenterId, value, categoryName).then(
      () => {
        setShowIvrMessageModal(false);
        setShowCallCenterMessageModal(false);
      }
    );
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const messageTableColumns = [
    {
      name: <ColumnToolTip data="Category" />,
      selector: (row: MessageViewModel) => (
        <ColumnToolTip data={row.category} />
      ),
    },
    {
      name: <ColumnToolTip data="Title" />,
      selector: (row: MessageViewModel) => {
        return <ColumnToolTip data={row.title} />;
      },
    },
    {
      name: <ColumnToolTip data="Message" />,
      selector: (row: MessageViewModel) => {
        console.log("`${FileBucketURL.Root}${row.audioMediaKey}`:", `${FileBucketURL.Root}${row.audioMediaKey}`)
        return row.isTtsEnabled ? (
          <ColumnToolTip data={row.message} />
        ) : (
          <audio controls>
            <source src={`${FileBucketURL.Root}${row.audioMediaKey}`}></source>
          </audio>
        );
      },
    },
    {
      name: <ColumnToolTip data={"Actions"} />,
      selector: (row: any) => {
        return (
          <>
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                setDataToEdit(row);
                if (row.category == "IVR_MESSAGE")
                  setShowIvrMessageModal?.(true);
                else setShowCallCenterMessageModal(true);
              }}
            >
              <span >
                <AssetSvg.Edit />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageBreadCrumbTile="Call Center"
        pageTitle="Message"
        tableHeaders={messageTableColumns}
        tableData={CallCenterMessageList || []}
        hideSearchBar={true}
        isPaging={false}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        onChangePageIndex={onChangePageIndex}
        pageIndex={pageIndex}
      />
      {showIvrMessageModal && (
        <ModalMessageEditModal
          show={showIvrMessageModal}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
          handleShow={setShowIvrMessageModal}
          onSaveHandler={onSaveHandler}
        />
      )}
      <ModalCallCenterMessageEditModal
        show={showCallCenterMessageModal}
        dataToEdit={dataToEdit}
        setDataToEdit={setDataToEdit}
        handleShow={setShowCallCenterMessageModal}
        onSaveHandler={onSaveHandler}
      />
    </>
  );
}
